import Vue from 'vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import Actions from "@/components/Actions";
import Modal from "@/components/Modal";
import InputFile from "@/components/InputFile";

Vue.component(FeatherIcon.name, FeatherIcon)
Vue.component("Actions",Actions);
Vue.component("InputFile",InputFile);
Vue.component("Modal",Modal);

Vue.prototype.$showError = function (message) {
    this.$toast({
        component: ToastificationContent,
        props: {
            title: 'Notificación',
            icon: 'AlertCircleIcon',
            variant: 'warning',
            text: message
        },
    },{
        position : 'top-center',
    })
}

Vue.prototype.$showSuccess = function (message) {
    this.$toast({
        component: ToastificationContent,
        props: {
            title: 'Notificación',
            icon: 'CheckCircle',
            variant: 'success',
            text: message
        },
    },{
        position : 'top-center',
    })
}
