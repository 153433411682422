import axiosIns from "@/libs/axios";
import store from "store2";
import main from "@/values/main";

export default class AuthService {
    static login(email, password) {
        return axiosIns.post('/auth/login', { email, password })
    }

    static async logout() {
        await axiosIns.post('/auth/logout')
        AuthService.removeToken()
        return true
    }

    static setToken(token) {
        localStorage.setItem(main.storageTokenKeyName, token)
    }

    static getToken(){
        return store.get(main.storageTokenKeyName)
    }

    static removeToken() {
        localStorage.removeItem(main.storageTokenKeyName)
        store.remove(main.storageUserKey)
    }
}
