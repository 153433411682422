<template>
	<div class="w-100 py-3 border rounded d-flex justify-content-center align-items-center cursor-pointer" @click="$refs.choosefile.click()">
		<span v-if="!file">Seleccionar archivo..</span>
		<span class="font-weight-bolder" v-else>{{file['name']}}</span>

		<input 
			type="file" 
			name="file" 
			class="d-none"
			:accept="accept"
			@change="fileChoosed"
			ref="choosefile">
	</div>
</template>
<script type="text/javascript">
	export default{
		props:["accept"],
		data(){
			return{
				file:null
			}
		},
		methods:{
			fileChoosed(evt){
				this.file = event.target.files[0];
				if(this.file){
					this.$emit("success",this.file);
				}else{
					this.$emit("error");
				}
			}
		}
	}
</script>