const route = Object.freeze({
    login: {
        name: 'login',
        path: '/login',
        full: true
    },
    error_404: {
        name: 'error-404',
        path: '/error-404',
        full: true
    },
    home: {
        title: 'Inicio',
        name: 'home',
        path: '/',
        component: () => import('@/views/Home.vue')
    },
    category: {
        title: 'Categorias',
        name: 'categories',
        path: '/categories',
        component: () => import('@/views/catalogs/category/Index.vue')
    },
    service: {
        title: 'Servicios',
        name: 'services',
        path: '/services',
        component: () => import('@/views/catalogs/service/Index.vue')
    },
    profession: {
        title: 'Profesiones',
        name: 'professions',
        path: '/professions',
        component: () => import('@/views/catalogs/profession/Index.vue')
    },
    area: {
        title: 'Areas',
        name: 'areas',
        path: '/areas',
        component: () => import('@/views/catalogs/area/Index.vue')
    },
})

export default route
