<template>
  <div>
    <!-- basic modal -->
    <b-modal :id="this.id" :title="title" hide-footer>
      <b-card-text>
      	<slot name="body"></slot>
      	<slot name="footer"></slot>
      </b-card-text>
    </b-modal>
  </div>
</template>
<script>
	import { BModal, BButton, VBModal, BAlert, BCardText} from 'bootstrap-vue';
	export default {
		props:["title"],
		components:{
			BModal,
			VBModal,
			BButton,
			BCardText
		},
    data(){
      return {
        id : '_' + Math.random().toString(36).substr(2, 9)
      }
    },
		methods:{
			launch(){
				this.$bvModal.show(this.id)
			},
      close(){
        this.$bvModal.hide(this.id)
      }
		}
	}
</script>
