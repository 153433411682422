<template>
	<div>
		<b-dropdown
			id="dropdown-1"
			text="Opciones"
			variant="primary">
			<slot name="options"></slot>
		</b-dropdown>
		
	</div>
</template>
<script type="text/javascript">
	import {
	  BDropdown
	} from 'bootstrap-vue'
	export default{
		components:{
			BDropdown,
		},
	}
</script>