import store from "store2";
import UserService from "@/services/UserService";
import main from "@/values/main";

export default {
    namespaced: true,
    state: {
        user: store.get(main.storageUserKey) || null,
    },
    mutations: {
        storeUser(state, data) {
            store.set(main.storageUserKey, data)
            state.user = data
        },
    },
    actions: {
        async getUser(state) {
            try {
                const response = await UserService.getProfile()
                state.commit('storeUser', response.data.data.item)
                return true
            } catch (e) {
                return false
            }
        },
    },
}
