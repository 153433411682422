import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from "@/values/routes";
import store from "@/store";
import AuthService from "@/services/AuthService";

Vue.use(VueRouter)

const listRoutes = Object.entries(routes).filter(i => !i[1].hasOwnProperty('full') ).map(route => {
  return {
    path: route[1]['path'],
    name: route[1]['name'],
    component: route[1]['component'],
    meta: {
      pageTitle: route[1]['title'],
      breadcrumb: [ { text: route[1]['title'], active: true } ],
    },
  }
})

const listAuth = Object.entries(routes).filter(i => !i[1].hasOwnProperty('full') ).map(route => route[1]['name'] )

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    ...listRoutes,
    {
      path: routes.login.path,
      name: routes.login.name,
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: routes.error_404.path,
      name: routes.error_404.name,
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})


router.beforeEach(  async (to, from, next) => {
  const user = await store.state.user.user;
  const authenticated = !!(user && AuthService.getToken());
  if (to.name === routes.login.name && authenticated){
    next({ name: routes.home.name})
  }else if (listAuth.includes(to.name) && !authenticated){
    AuthService.removeToken()
    next({ name: routes.login.name})
  }else next()
})



router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
