import Vue from 'vue'
import main from "@/values/main";
import axios from 'axios'
import routes from "@/values/routes";
import router from "@/router";
import AuthService from "@/services/AuthService";

const axiosIns = axios.create({
  baseURL: main.baseURL,
})

axiosIns.interceptors.request.use(function (config) {
  const accessToken = AuthService.getToken()
  if (accessToken) {
    config.headers.Authorization = `${main.tokenType} ${accessToken}`
  }
  return config;
}, function (error) {
  return Promise.reject(error);
});

axiosIns.interceptors.response.use(function (response) {
  return response;
}, async function (error) {
  const {response} = error
  if (response && response.data.message === "Unauthenticated.") {
    await AuthService.removeToken()
    await router.push({ name: routes.login.name, replace: true })
  }
  return Promise.reject(error);
});

Vue.prototype.$http = axiosIns

export default axiosIns
